import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const WorkPage = () => {
  return (
    <Layout>
      <Seo title="Work" />

      <div className="flex flex-col gap-8">
        <h1 className="mb-10">Work</h1>

        <div className="flex flex-col">
          <h4 className="mt-0">Experience</h4>

          <ul className="list-none">
            <li className="mb-1 flex flex-row items-center justify-between">
              <a href="https://www.clevertech.biz/" title="Clevertech" target="_blank" rel="noopener noreferrer">Clevertech</a>
              <span className="text-xs">(ruby developer)</span>
            </li>
            <li className="mb-1 flex flex-row items-center justify-between">
              <a href="https://sporticted.com" title="Sporticted" target="_blank" rel="noopener noreferrer">Sporticted</a>
              <span className="text-xs">(co-founder)</span>
            </li>
            <li className="mb-1 flex flex-row items-center justify-between">
              <a href="https://thestay.app" title="The Stay App" target="_blank" rel="noopener noreferrer">The Stay</a>
              <span className="text-xs">(co-founder)</span>
            </li>
            <li className="mb-1 flex flex-row items-center justify-between">
              <a href="https://willgo.app" title="Willgo App" target="_blank" rel="noopener noreferrer">Willgo</a>
              <span className="text-xs">(co-founder)</span>
            </li>
            <li className="mb-1 flex flex-row items-center justify-between">
              <a href="https://www.lomography.com" title="Lomography" target="_blank" rel="noopener noreferrer">Lomography</a>
              <span className="text-xs">(software engineer)</span>
            </li>
            <li className="mb-1 flex flex-row items-center justify-between">
              <span>Kinta</span>
              <span className="text-xs">(co-founder)</span>
            </li>
            <li className="mb-1 flex flex-row items-center justify-between">
              <a href="https://www.quadraccess.com/" title="Quadra" target="_blank" rel="noopener noreferrer">Stock tool for Quadra</a>
              <span className="text-xs">(contract)</span>
            </li>
            <li className="mb-1 flex flex-row items-center justify-between">
              <span>Quibsta</span>
              <span className="text-xs">(contract)</span>
            </li>
          </ul>

          <h4>OSS</h4>

          <ul className="list-node">
            <li className="mb-1"><a href="https://github.com/lomography/wolfe" title="Wolfe Repo" target="_blank" rel="noopener noreferrer">Wolfe</a></li>
            <li className="mb-1"><a href="https://github.com/lomography/dark_links" title="Dark Links Repo" target="_blank" rel="noopener noreferrer">Dark Links</a></li>
            <li className="mb-1"><a href="https://github.com/Terrastories/terrastories" title="Terrastories Repo" target="_blank" rel="noopener noreferrer">Terrastories</a></li>
            <li className="mb-1"><a href="https://github.com/rgenchev/data_structures" title="Data Structures Repo" target="_blank" rel="noopener noreferrer">Data Structures</a></li>
            <li className="mb-1"><a href="https://github.com/rgenchev/crew" title="Crew App Repo" target="_blank" rel="noopener noreferrer">Crew</a></li>
            <li className="mb-1"><a href="https://github.com/rgenchev/ansible-example" title="Ansible Example Repo" target="_blank" rel="noopener noreferrer">Ansible Example</a></li>
            <li className="mb-1"><a href="https://github.com/rgenchev/nginx-config-for-rails-apps" title="Nginx Config for Rails Apps Repo" target="_blank" rel="noopener noreferrer">Nginx Config for Rails Apps</a></li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default WorkPage
